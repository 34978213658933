@import 'styles/reset.css';

@import 'styles/variables.less';
@import 'styles/mixins.less';

:root {
  --gcc-arrow-offset: 30px;
}

.arrow-primary-top {

  &:after,
  &:before {
    left: var(--gcc-arrow-offset) !important;
  }
}

html {
  scroll-behavior: smooth;
  font-size: 14px;
  box-sizing: border-box;
}

@font-face {
  font-family: 'password';
  font-style: normal;
  font-weight: 400;
  src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}

.password-field {

  &,
  &>input {
    font-family: 'password';
    letter-spacing: 1px;
    color: @text-color;
  }
}

body {
  margin: 0;
  font-family: Figtree, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .page-container {
    min-height: 100vh;
    width: 100%;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  background-color: rgba(46, 91, 255, 0.01) !important;
  color: @text-color !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: @text-color !important;
}

.ant-form-item.ant-form-item-has-error {

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:active,
  input:-webkit-autofill:focus {
    color: @text-color !important;
    background-color: @red-bg !important;
    -webkit-box-shadow: 0 0 0 1000px @red-bg inset !important;
    -webkit-text-fill-color: @text-color !important;
  }
}

.ant-input,
.ant-input-affix-wrapper {
  border-radius: 4px !important;
}

.ant-card {
  border-radius: 2px !important;
}

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.fullscreen-modal {
  margin: 0;
  padding: 0;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
  max-width: 100% !important;

  .ant-modal-content {
    background: transparent;
    margin-top: 0;
    height: 100%;
    width: 100%;

    .ant-modal-body {
      padding: 0;
    }
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.focus-highlight {

  // @apply focus:ring-2 ring-primary ring-offset-primary ring-offset-1 rounded-sm;
  &.focus-visible {
    @apply ring-2 ring-primary;
  }
}

.text-highlight {
  background-color: @highlight-color;
}

a,
button {
  &.focus-visible {
    @apply ring-2 ring-primary;
  }
}

h1,
h1.ant-typography {
  font-size: 38px;
  font-weight: 800;
  line-height: 46px;
}

h2,
h2.ant-typography {
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
}

h3,
h3.ant-typography {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h4,
h4.ant-typography {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

h5,
h5.ant-typography {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

h1,
h2,
h3,
h4,
h5 {
  &.font-light {
    font-weight: 300;
  }

  &.font-normal {
    font-weight: 400;
  }

  &.font-medium {
    font-weight: 500;
  }

  &.font-semibold {
    font-weight: 600;
  }

  &.font-bold {
    font-weight: 700;
  }
}

.ant-typography.ant-typography-secondary {
  color: #4b5563;
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography {
  &.ant-typography-secondary {
    color: #374151;
  }
}

.primary {

  a,
  button {

    // @apply focus:ring-2 ring-white ring-offset-white ring-offset-1 rounded-sm;
    &.focus-visible {
      @apply ring-2 ring-primary ring-opacity-50;
    }
  }
}

.white {

  a,
  button {

    // @apply focus:ring-2 ring-primary ring-offset-primary ring-offset-1 rounded-sm;
    &.focus-visible {
      @apply ring-2 ring-primary rounded-sm;
    }
  }
}

.error-border {
  border: solid 1px @red-6;
}

.error-text {
  color: @red-6;
}

.error-bg {
  background: @red-bg;
}

.disabled-icon {
  @apply border-0;

  svg {
    @apply text-gray-400;
  }

  &:hover {
    @apply ring-0 bg-transparent;
  }
}

.ant-btn-primary.faded {
  background: @primary-color-faded;
  border: none;

  span,
  svg {
    color: @primary-color;
  }

  &.active,
  &:focus,
  &:active,
  &:hover {
    background: rgba(@primary-color, 0.3) !important;
  }
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-color: #fff;
}

.layout-page {
  background-color: #f9fafb;
  font-weight: 400;
}

.ant-layout {
  background-color: @bg-color;
}

.content-container {
  padding: 15px;
  width: 100%;

  @media (min-width: @screen-sm) {
    padding: 20px;
  }

  @media (min-width: @screen-md) {
    padding: 29px 24px;
  }

  @media (min-width: @screen-xxl) {
    width: calc(100% - 360px);
  }
}

a {
  @apply text-primary hover:text-primary;
}

.-mt-1px {
  margin-top: -1px;
}

.required-star {
  font-family: sans-serif;
  @apply text-red-500 absolute -left-3 -top-2;
}

.heading-container {
  margin: 3px 0 10px;

  h1 {
    color: @text-color;
    font-family: Figtree;
    font-size: 28px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 32px;
  }
}

.white-box {
  box-sizing: border-box;
  border: 1px solid rgba(46, 91, 255, 0.08);
  border-radius: 1px;
  background-color: @white;
}

.page-title {
  @apply text-15 font-semibold uppercase text-primary leading-5;
  letter-spacing: 1.21px;
}

.section-title {
  @apply text-lg font-bold uppercase text-primary leading-4;
  letter-spacing: 1.21px;
}

.section-border {
  @apply border-0 border-b-2 border-primary-faded;
}

.section-border-top {
  @apply border-0 border-t-2 border-primary-faded;
}

.section-px {
  @apply px-5 md:px-7 xl:px-8;
}

.section {
  padding: 20px;
  margin-top: -1px;

  @media (min-width: @screen-sm) {
    padding: 24px 24px 24px 30px;
  }

  .section-title {
    margin-left: -8px;
    margin-bottom: 20px;
  }

  .instructions {
    color: @text-color;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
  }

  .heading-color {
    color: @section-heading-color;
  }

  .description-color {
    color: @text-color;
  }
}

.buttons {
  display: flex;
  flex-direction: column;

  .ant-btn {
    width: 100%;
    margin-bottom: 10px;
  }

  @media (min-width: @screen-sm) {
    flex-direction: row;

    .ant-btn {
      min-width: 132px;
      width: auto;
      margin-bottom: 0;
      margin-right: 10px;
    }
  }
}

.actions {
  @apply flex flex-col-reverse sm:flex-row space-y-reverse space-y-2 sm:space-y-0 sm:space-x-2 sm:justify-end;
}

.ant-modal-root {
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.35);
  }
}

.gcc-steps {
  &.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail::after {
    height: calc(100% - 5px);
    position: relative;
    top: 4px;
  }

  .ant-steps-item {
    .ant-steps-item-icon {
      margin-left: 0 !important;
      color: rgba(46, 91, 255, 0.15);
    }

    .ant-steps-item-container {
      .ant-steps-item-tail {
        // margin-left: 0;
        width: calc(100% - 10px);
        margin-left: 9px;

        &::after {
          background-color: rgba(46, 91, 255, 0.15);
          margin-left: 22px;
          position: relative;
          top: 2px;
        }

        @media (max-width: @screen-sm) {
          &::after {
            margin-left: 25px;
          }
        }
      }

      .ant-steps-item-content {
        width: 100%;
        text-align: left;
        margin-top: 22px;

        >.ant-steps-item-title {
          color: @text-color;
        }

        >.ant-steps-item-description {
          color: @text-color-secondary;
        }

        @media (max-width: @screen-sm) {
          padding-left: 48px;
          top: -23px;
          position: relative;
        }
      }
    }

    &:nth-child(1) {
      .ant-steps-item-container {
        .ant-steps-item-tail {
          margin-left: 18px;
          width: calc(100% - 19px);

          @media (max-width: @screen-sm) {
            &::after {
              margin-left: 6px;
              margin-top: 10px;
            }
          }
        }
      }
    }

    &:last-child {
      .ant-steps-item-container {
        .ant-steps-item-tail {
          margin-left: -15px;
        }
      }
    }

    &.ant-steps-item-active {
      .step-dot {
        background-color: @primary-color;
        border: none;

        svg,
        span {
          color: #fff;
        }
      }
    }

    &.ant-steps-item-finish {
      .step-dot {
        background-color: @primary-color-faded;
        border: none;

        svg {
          color: @primary-color;
          font-size: 17px;
        }
      }
    }

    &.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
      color: @text-color-secondary;
    }
  }
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  @apply text-red-700;
}

.ant-form-item-label {
  padding-bottom: 8px !important;

  >label {
    color: @text-color;
    font-family: Figtree;
    font-size: 12px !important;
    line-height: 14px !important;
    text-transform: uppercase;
    color: #393939 !important;
    font-weight: 700;
    letter-spacing: 1.29px;
    line-height: 0;
    white-space: nowrap;

    @media (max-width: @screen-xs) {
      height: auto;
    }
  }
}

.change-request-item {
  border: solid 1px @change-request-color !important;
  background-color: @change-request-bg-color !important;
}

.select__control {
  height: 40px;
  width: 100%;
  border: 1px solid @input-border-color;
  border-radius: 5px;
  background-color: @bg-color-with-opacity;

  &.select__control--is-focused {
    border-color: @primary-color;
    box-shadow: none;
  }

  &.select__control--is-disabled {
    pointer-events: none;
    border-color: @input-disabled-border-color !important;
    color: @input-disabled-color !important;
    opacity: 0.4;
  }

  .select__indicator.select__clear-indicator,
  .select__indicator.select__dropdown-indicator {
    color: @input-icon-color;
  }
}

.select__control {
  border: 1px solid @input-border-color;
}

.select__menu {
  margin: 3px 0 !important;
  border-radius: 2px !important;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
}

.select__option {
  margin-top: 1px !important;

  &.select__option--is-focused {
    background-color: @primary-color-faded !important;
  }
}

.ant-select-item.ant-select-item-option {
  @apply py-2;
  margin-top: 1px;

  &:hover {
    background-color: @primary-color-faded;
  }
}

.select__option--is-focused,
.select__option--is-selected,
.ant-select-item-option-active:not(.ant-select-item-option-disabled),
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  @apply !bg-primary !bg-opacity-5;
  color: @text-color !important;
}

.checkbox-label {
  color: #767e90;
  font-family: Figtree;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 10px;
  display: block;
  position: relative;
}

.gcc-switch-inline-form-item {
  .ant-form-item-label {
    position: absolute;
    left: 160px;
    top: 4px;

    label {
      color: @text-color;
      font-family: Figtree;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 22px;
      text-transform: none;

      @media (max-width: @screen-xs) {
        height: auto;
      }
    }
  }
}

.gcc-switch-inline-form-item-left-fullwidth {
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: row !important;
  // justify-content: space-between;
  align-items: center;

  .ant-form-item-label {
    flex-basis: 60%;
    margin-right: 60px;

    label {
      color: @text-color;
      font-family: Figtree;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 22px;
      text-transform: none;
      font-weight: 500;

      @media (max-width: @screen-xs) {
        height: auto;
      }
    }
  }

  .ant-form-item-control {
    width: 120px;
    flex: 0;
  }
}

.more-items {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 56px;
  border-radius: 16px;
  background-color: rgba(46, 91, 255, 0.2);
}

.add-margin(@n, @direction, @base, @i: 0) when (@i =< @n) {
  &-@{base}-@{i} {
    margin-@{direction}: @i * @base !important;
  }

  .add-margin(@n, @direction, @base, (@i + 1));
}

.disabled-children {
  * {
    pointer-events: none !important;
  }
}

.color {
  &-primary {
    color: @primary-color !important;
  }

  &-primary-faded {
    color: @primary-color-faded !important;
  }

  &-white {
    color: @white !important;
  }

  &-text {
    color: @text-color !important;
  }

  &-grey {
    color: #767e90 !important;
  }

  &-light-grey,
  &-label {
    color: @text-color !important;
  }

  &-section-heading {
    color: @text-color-secondary !important;
  }

  &-border {
    color: #e0e7ff !important;
  }

  &-icon {
    color: #bfc5d2 !important;
  }
}

.submenu-arrow-left {

  // position: relative;
  &::after {
    position: absolute;
    top: 19px;
    left: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;

    border-right: 10px solid white;
    @apply shadow-lg;
  }
}

.submenu-arrow-right {

  // position: relative;
  &::after {
    position: absolute;
    top: 19px;
    right: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;

    border-left: 10px solid white;
    @apply shadow-lg;
  }
}

.arrow {
  &::after {
    position: absolute;
    width: 0;
    height: 0;
    @apply shadow-lg;
  }

  &.arrow-top {
    &::after {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid white;
    }
  }

  &.arrow-bottom {
    &::after {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid white;
    }
  }

  &.arrow-left {
    &::after {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid white;
    }
  }

  &.arrow-right {
    &::after {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid white;
    }
  }
}

// Antd overrides
@import './overrides/antd/index.less';
// React Aria overrides
@import './overrides/react-aria/index.less';
@white: #ffffff;@blue: #2e5bff;@primary-color: #2e5bff;@green-6: #33ac2e;@red-6: #d13f3f;@red-bg: #fef7f7;@change-request-color: #fb923c;@change-request-bg-color: #fff7ed;@success-color: #33ac2e;@primary-color-faded: rgba(@primary-color, 0.15);@highlight-color: #ffff00;@height-base: 40px;@header-menu-item-height: 34px;@tile-large-bg: #41a3fd;@tile-medium-bg: #38b6fa;@tile-small-bg: #38b6fa;@grey-4: #bbbbbb;@text-color: #2d384f;@text-color-secondary: #8798ad;@section-heading-color: @text-color-secondary;@bg-color: #fafbff;@bg-color-with-opacity: rgba(224, 231, 255, 0.2);@input-color: @text-color;@input-label-color: #69707f;@input-icon-color: #999999;@input-placeholder-color: #565d6e;@input-border-color: #afb8cb;@border-color-split: @input-border-color;@picker-border-color: @input-border-color;@icon-btn-border-color: #c6cedd;@input-bg: rgba(46, 91, 255, 0.01);@input-disabled-color: #565d6e;@input-disabled-border-color: #afb8cb;@gcc-border-color: #e0e7ff;@gcc-icon-color: #bfc5d2;@bg-table-row: #f5f7fa;@gold-6: #e3ab53;@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;@screen-xs-max: (@screen-sm-min - 1px);@screen-sm-max: (@screen-md-min - 1px);@screen-md-max: (@screen-lg-min - 1px);@screen-lg-max: (@screen-xl-min - 1px);@screen-xl-max: (@screen-xxl-min - 1px);